import React, { Component } from 'react';
import './autoComplete.css';
import { extend } from 'jquery';

class autoComplete extends Component {

    selectItem = (event) => {
        this.props.onSelectAutoComplete(event);
    }

    render(){
        return (
            <li className='autoCompleteLi'
                onClick={this.selectItem}
                id={this.props.name}
                data-lat={this.props.lat}
                data-lon={this.props.lon}
                >
                
                {this.props.name}
               
            </li>
         );
    }
}

export default autoComplete;
