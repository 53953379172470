import React, { Component } from 'react';
import SideMenuPoints from './SideMenuPoints';
import './SideMenu.css';

class SideMenu extends Component {

    render() {

        let sideMenuPoints = null;
        let searchResult = null;

        if (this.props.points && this.props.points.length) {

            sideMenuPoints = this.props.points.map(x => {
                return (<SideMenuPoints
                    key={x.getProperties().nameId}
                    points={x.getProperties()} map={this.props.map}
                    datasource={this.props.datasource}
                    popup={this.props.popup}
                    copyCalloutToClipboard={this.props.copyCalloutToClipboard}
                />);
            });

            let message = this.props.points.length > 1 ? "results found:" : "result found:";

            searchResult = <p className="sideMenuMessage">{this.props.points.length} {message} </p>;

        } else if (this.props.errorMessage) {

            searchResult = <p className="sideMenuMessage">{this.props.errorMessage} </p>;
        } else {
            searchResult = <p className="sideMenuMessage"> Enter a search to see nearest locations </p>;
        }

        return (
            <div className={this.props.cssClass}>
                { searchResult }
                {sideMenuPoints }
            </div>
        );
    }
}

export default SideMenu;