import React, { Component } from 'react';
import * as atlas from 'azure-maps-control';
import Toolbar from './Toolbar';
import { atlasClickEventHandlerClick } from './Atlas/atlasEventHandlerClick';
import { atlasEventHandlerMove } from './Atlas/atlasEventHandlerMove';
import { atlasEventHandlerClosePopup } from './Atlas/atlasEventHandlerClose';
import eztIcon from './images/eztDefaultImg.svg'; 
import './Map.css';
import './../../node_modules/azure-maps-control/dist/atlas.min.css';
import SideMenu from './SideMenu';
import Version from './Version';
import { eztPointsToFeatureArr, eztMarkupToFeatureArr } from './Utils/EztJsonToFeatureArr';
import { searchShowPolygon, searchForPolygon, showPolygons } from './Utils/SearchShowPolygon';
import { searchShowPoint, searchForPoint, showPointCallout } from './Utils/SearchShowPoint';

class Map extends Component {

    state = {
        map: '',
        datasource: '',
        popup: '',
        primaryNameCol: '',
        mapReady: false,
        configurationError: '',
        searchPoint: {
            lat: 0,
            lon: 0,
            fullAddress: '',
            errorMessage: '',
        },
        showMenu: false,
        updateMap: false,
        pointsInPolygons: [],
        polygonsContainPoints: []
    };

    componentDidMount() {

        const map = new atlas.Map('map', {
            center: [-98.5795, 39.8283],
            zoom: 3,
            view: "Auto",
            dragRotateInteraction: false,
            authOptions: {
                authType: 'subscriptionKey',
                subscriptionKey: this.props.azureKey
            }
        });
           
        map.events.add('ready', () => {

            map.events.add('error', (e) => {

                console.log('Ezt Locator App Map Error: ', e);

            });

            var project = this.props.project;

            if (project && project.eztPoints.length && project.eztPoints[0].primaryNameCol.id) {

                var primaryNameCol = project.eztPoints[0].primaryNameCol;

                var pointLayerIcon = project.logo ? project.logo : eztIcon;

                //Load the custom image icon into the map resources.
                map.imageSprite.add('customer-custom-icon', pointLayerIcon).then(() => {

                    // parse the points from ezt into a azure feature array
                    var featureArray = eztPointsToFeatureArr(project.eztPoints[0]);

                    var featureCollection = new atlas.data.FeatureCollection(featureArray);

                    var datasource = new atlas.source.DataSource('eztFeatures');

                    map.sources.add(datasource);

                    var symbolLayer = new atlas.layer.SymbolLayer(datasource, null, {
                        iconOptions: {
                            image: 'customer-custom-icon',
                            anchor: 'center',
                            allowOverlap: true,
                            size: .3
                        },
                        filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
                    });

                    var polygonLayer = new atlas.layer.PolygonLayer(datasource, null, {
                        fillColor: ['get', 'fillColor'],
                        fillOpacity: ['get', 'fillOpacity'],
                        filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]	//Only render Polygon or MultiPolygon in this layer.
                    });

                    map.layers.add([polygonLayer, symbolLayer]);

                    datasource.add(featureCollection);

                    map.setCamera({ bounds: atlas.data.BoundingBox.fromData(datasource.toJson()), padding:{ top: 25, bottom: 25, left: 25, right: 25 }, type: 'fly' });

                    var polyFeatureArr = eztMarkupToFeatureArr(project.eztMarkup);

                    var polyFeatureCollection = new atlas.data.FeatureCollection(polyFeatureArr);

                    datasource.add(polyFeatureCollection);

                    var popup = new atlas.Popup();

                    map.events.add('click', (e) => { atlasClickEventHandlerClick(e, datasource, popup, this.props.copyCalloutToClipboard) });

                    map.events.add('close', popup, function (ev) {
                        atlasEventHandlerClosePopup(ev, map, datasource, false);
                    });

                    map.events.add('move', function (e) { atlasEventHandlerMove(e, popup) });

                    // azure maps control for zoom in and out
                    map.controls.add(new atlas.control.ZoomControl({
                        zoomDelta: 1,
                        style: atlas.ControlStyle.light
                    }), { position: 'top-right'});

                    if (!project.eztMarkup.length) {
                        console.log('Ezt Locator App Map Error: Markup not found');
                    }

                    this.setState({ map: map, datasource: datasource, primaryNameCol: primaryNameCol, mapReady: true, popup: popup, updateMap: false });

                });
            } else {

                var errorMessage = "Error Loading Project:\r\n";

                if (!project.eztPoints.length) {

                    errorMessage += "Point layer not found.\r\n";

                } else if (!project.eztPoints[0].primaryNameCol.id) {

                    errorMessage += "Primary name column not found.\r\n";
                }

                if (!project.eztMarkup.length) {

                    errorMessage += "Markup not found.\r\n";
                }

                errorMessage += "Please check project configuration in EasyTerritory"

                console.log('Ezt Locator App Map Error: ', errorMessage);

                this.setState({ configurationError: errorMessage });
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.updateMap && this.state.searchPoint.lat && this.state.searchPoint.lon) {

            // if marker exists remove it
            if (this.state.map.markers.getMarkers().length) {

                this.state.map.markers.clear();
            }

            // move map
           if (this.state.polygonsContainPoints.length) {

               // center on polygons
               this.state.map.setCamera({ bounds: atlas.data.BoundingBox.fromData(this.state.polygonsContainPoints), type: 'fly' });
                
            } else {

                let center = [parseFloat(this.state.searchPoint.lon), parseFloat(this.state.searchPoint.lat)];

                // center the map at that searched location
                this.state.map.setCamera({ center: center, type: 'fly' });

            }

            // drop html marker on map
            let searchMarker = new atlas.HtmlMarker({
                htmlContent: "<div><div class='pin bounce'></div><div class='pulse'></div></div>",
                position: [this.state.searchPoint.lon, this.state.searchPoint.lat],
                pixelOffset: [5, -18]
            });

            this.state.map.markers.add(searchMarker);

            // show point callout if there is just one point
            if (this.state.pointsInPolygons.length === 1) {

                showPointCallout(this.state.map, this.state.popup, this.state.pointsInPolygons[0], this.props.copyCalloutToClipboard);

            } else if(!this.state.pointsInPolygons.length) {

                if (this.state.popup.isOpen()) {

                    this.state.popup.close();
                }

                let shapes = this.state.datasource.getShapes();

                for (var i = 0; i < shapes.length; i++) {

                    let shape = shapes[i];
                    let type = shape.getType();

                    if ((type === "Polygon" || type === "MultiPolygon") && shape.getProperties().fillOpacity !== 0) {

                        shape.addProperty('fillOpacity', 0);
                    }

                }
            }

            if (this.state.polygonsContainPoints.length) {

                let shapes = this.state.datasource.getShapes();

                showPolygons(this.state.polygonsContainPoints, shapes);
            }

        }

    }

    geocodedSearch = (lat, lon, address, error) => {

        if (!error) {

            //let pointsInPolygons = this.searchBarAction(lat, lon);
            const shapes = this.state.datasource.getShapes();

            // do a spatial search to find the territory that geocoded point falls in
            const polygonsFound = searchForPolygon(shapes, lat, lon);

            //let pointsInPolygons = searchShowPoint(shapes, polygonSelected, this.state);

            // look for the point from point layer with one to one relationship with the found polygon
            const pointsInPolygons = searchForPoint(shapes, polygonsFound, this.state);

            if (pointsInPolygons.length > 1) {

                this.setState({ searchPoint: { lat: lat, lon: lon, fullAddress: address }, showMenu: true, pointsInPolygons: pointsInPolygons, polygonsContainPoints: polygonsFound, updateMap: true});

            } else {

                this.setState({ searchPoint: { lat: lat, lon: lon, fullAddress: address, errorMessage: '' }, showMenu: false, pointsInPolygons: pointsInPolygons, polygonsContainPoints: polygonsFound, updateMap: true});
            }

        } else {
            this.setState({ searchPoint: { lat: '', lon: '', fullAddress: '', errorMessage: error }, pointsInPolygons: [], showMenu: true, polygonsContainPoints: [] });
        }

    };

    menuButtonClicked = () => {

        const doesShow = this.state.showMenu;
        this.setState({ showMenu: !doesShow, updateMap: false });
    }

    searchBarAction = (lat, lon) => {

        /*
        if (this.state.map.markers.getMarkers().length) {

            this.state.map.markers.clear();
        }
        */

        let shapes = this.state.datasource.getShapes();

        let polygonSelected = searchForPolygon(shapes, lat, lon);

        return polygonSelected;
        /*
            // take the lat and lon from search and find the polygon
            var polygonSelected = searchShowPolygon(shapes, this.state, lat, lon);

            // look for the point from point layer with one to one relationship with the found polygon
            let pointsInPolygons = searchShowPoint(shapes, polygonSelected, this.state);

            let searchMarker = new atlas.HtmlMarker({
                htmlContent: "<div><div class='pin bounce'></div><div class='pulse'></div></div>",
                position: [lon, lat],
                pixelOffset: [5, -18]
            });

            this.state.map.markers.add(searchMarker);
 
            return pointsInPolygons;
        */
    }

    render() {

        let errorMessage = this.state.configurationError ? this.state.configurationError : this.state.searchPoint.errorMessage;
 
        let sideMenu = 'sideMenu sideMenuClose';

        if (this.state.showMenu || this.state.configurationError) {

            sideMenu = 'sideMenu sideMenuOpen';
        } 

        return (
            <React.Fragment>
                <Toolbar onSearch={this.geocodedSearch}
                    searchCountryCode={this.props.searchCountryCode}
                    azureKey={this.props.azureKey}
                    key="eztToolBar"
                    menuButtonClicked={this.menuButtonClicked}
                />  
                <div id= 'map'>
                    <SideMenu
                        points={this.state.pointsInPolygons}
                        errorMessage={errorMessage} map={this.state.map}
                        datasource={this.state.datasource} popup={this.state.popup}
                        cssClass={sideMenu}
                        copyCalloutToClipboard={this.props.copyCalloutToClipboard}
                    />
                    <Version number = '2.15'/>
                </div>
            </React.Fragment>
        );
    }

}

export default Map;