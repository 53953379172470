import React, { Component } from 'react';
import './MapConsole.css';

class MapConsoleMessage extends Component {


    render() {
        return (

            <p className= 'MapConsoleMess'>{this.props.mess}</p>

        );
    }

}

export default MapConsoleMessage;