import * as atlas from 'azure-maps-control';
import { eztObject2Table } from './BuildHtml';
import { atlasEventHandlerClose } from './../Atlas/atlasEventHandlerClose';
import { atlasEventHandlerMove } from './../Atlas/atlasEventHandlerMove';

export function searchShowPoint(shapes, polygonSelected, thisState){

    thisState.map.popups.getPopups().forEach(p => { p.remove() });

    let pointsinPolygons = [];

        polygonSelected.forEach(y => {

            for (let i = 0; i < shapes.length; i++) {

                if (shapes[i].data.geometry.type === 'Point') {

                    if (y.getProperties().name.includes(shapes[i].getProperties().nameId)) {

                        const props = shapes[i].getProperties();

                        const content = eztObject2Table(props);

                        pointsinPolygons.push(shapes[i]);

                        if (polygonSelected.length === 1) {
                            let popup = new atlas.Popup();

                            let offSet = [0, 0];

                            // set the position of popup
                            if (popup.autoAnchor === 'bottom' || typeof popup.autoAnchor === 'undefined' || popup.autoAnchor === 'bottom-right' || popup.autoAnchor === 'bottom-left') {
                                offSet = [0, -16];

                            } else if (popup.autoAnchor === 'right') {
                                offSet = [-14, 0];

                            } else if (popup.autoAnchor === 'left') {
                                offSet = [14, 0];

                            } else if (popup.autoAnchor === 'top' || popup.autoAnchor === 'top-right' || popup.autoAnchor === 'top-left') {
                                offSet = [0, 16];

                            }

                            popup.setOptions({
                                content: content,
                                position: shapes[i].getCoordinates(),
                                draggable: false,
                                pixelOffset: offSet,
                                fillColor: 'rgba(255, 255, 255, .94)',
                                closeButton: true

                            });

                            popup.open(thisState.map);

                            thisState.map.events.add('close', popup, function (ev) {
                                atlasEventHandlerClose(ev, shapes, [y]);
                            });

                            thisState.map.events.add('move', function (e) { atlasEventHandlerMove(e, popup) });
                        }

                    }

                }

            }
        });

    return pointsinPolygons
}

export function searchForPoint(shapes, territoryFound) {

    const pointsinPolygons = [];

    // loop through the found territory
    for (var i = 0; i < territoryFound.length; i++) {

        const territory = territoryFound[i];

        // loop through the shapes
        for (var j = 0; j < shapes.length; j++) {

            const shape = shapes[j];

            if (shape.data.geometry.type === 'Point') {

                const props = shape.getProperties();

                // match the territory with the point
                // territory name has the name of point
                if (territory.getProperties().name.includes(props.nameId)) {

                    pointsinPolygons.push(shape);
                }
            }
        }
    }

    return pointsinPolygons;
}

export function showPointCallout(map, popup, pointInPolygon, copyCalloutToClipboard) {

    const content = eztObject2Table(pointInPolygon.getProperties(), copyCalloutToClipboard);

    let offSet = [0, 0];

    // set the position of popup
    if (popup.autoAnchor === 'bottom' || typeof popup.autoAnchor === 'undefined' || popup.autoAnchor === 'bottom-right' || popup.autoAnchor === 'bottom-left') {
        offSet = [0, -16];

    } else if (popup.autoAnchor === 'right') {
        offSet = [-14, 0];

    } else if (popup.autoAnchor === 'left') {
        offSet = [14, 0];

    } else if (popup.autoAnchor === 'top' || popup.autoAnchor === 'top-right' || popup.autoAnchor === 'top-left') {
        offSet = [0, 16];

    }

    popup.setOptions({
        content: content,
        position: pointInPolygon.getCoordinates(),
        draggable: false,
        pixelOffset: offSet,
        fillColor: 'rgba(255, 255, 255, .94)',
        closeButton: true

    });

    if (!popup.isOpen()) {

        popup.open(map);
    }
}