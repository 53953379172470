import React, { Component } from 'react';
import axios from 'axios';
import './Toolbar.css';
import MenuButton from './MenuButton';
import AutoComplete from './autoComplete';
import AutoCompleteErrorMess from './autoCompleteErrorMess';
import './InputBox.css';
import './InputButton.css';
import EztImage from './images/eztBlackAndWhiteLogo.svg';
import { StateToAbbrev } from './Utils/StateToAbbrev';
import { debounce } from 'lodash';

var CancelToken = axios.CancelToken;
var cancel;
var abort;

class Toolbar extends Component {

    state = {
        input: '',
        autoComplete: [],
        location: '',
    }

    selectAutoComplete = (e) => {

        this.props.onSearch(e.target.dataset.lat, e.target.dataset.lon, e.target.id, null);

        this.setState({ input: e.target.id, autoComplete: null });
    }

    apiCall = debounce((target) => {

        if (abort) {
            return;
        }

        this.getGeocodedResults(target);

    }, 750);

    onInput = (e) => {

        const value = e.target.value;

        if (value.length > 3) {

            abort = false;
            this.setState({ input: value });

        } else {

            abort = true;
            this.setState({ input: value, autoComplete: null });
        }

        this.apiCall(value);
    
    };

    searchClick = (target) => {

        if (target) {

            abort = true;
            this.getGeocodedResults(target, 'click');

        } else {

            this.props.onSearch(null, null, null, 'Enter an address to search');
        }

    }

    getLocation = () => {

        if (this.state.location === "") {

            if (!navigator.geolocation) {

                console.log('navigator not available');
                this.setState({ location: null });

            } else {

                navigator.geolocation.getCurrentPosition((pos) => {

                    let crd = pos.coords;

                    let lat = crd.latitude;
                    let lon = crd.longitude;

                    let url = "https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&query=" + lat + "," + lon + "&subscription-key=" + this.props.azureKey;

                    axios.get(url, { timeout: 5000 })
                        .then(x => {

                            let loc = x.data.addresses[0].address.countryCode;

                            this.setState({ location: loc });
                        })
                        .catch(err => {

                            console.log('navigator error', err);
                            this.setState({ location: "US" });
                        });

                }, (err) => {

                    this.setState({ location: "US" });
                });
            }
        }
    }

    getGeocodedResults = (value, callInvokedBy) => {

        const query = encodeURIComponent(value.trim());

        let url = 'https://atlas.microsoft.com/search/address/json?typeahead=true&subscription-key=' + this.props.azureKey + '&api-version=1&query=' + query + '&view=Auto';

        if (this.props.searchCountryCode) {

            const countrySet = 'countrySet=' + this.props.searchCountryCode;
            const queryCountryCode = encodeURIComponent(value + ' ' + this.props.searchCountryCode);
            url = 'https://atlas.microsoft.com/search/address/json?typeahead=true&subscription-key=' + this.props.azureKey + '&api-version=1&query=' + queryCountryCode + '&' + countrySet + '&view=Auto';

        }

        if (cancel != undefined) {
 
            cancel();
        }

        let autoComplete = [];

        axios.get(url, {
            timeout: 3000,
            cancelToken:
                new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
        })
        .then(x => {

            if (x.data.results && x.data.results.length) {

                let results = x.data.results;

                if (this.state.location) {

                    results = this.orderResults(results);
                }

                for (var i = 0; i < results.length; i++) {
                    autoComplete.push(<AutoComplete
                        key={results[i].id}
                        name={results[i].address.freeformAddress}
                        lat={results[i].position.lat}
                        lon={results[i].position.lon}
                        onSelectAutoComplete={this.selectAutoComplete}
                    />);
                }

                this.setState({
                    autoComplete: autoComplete
                });

            } else {

                console.log('No results returned for search '+ value);

                if (callInvokedBy === 'enter' || callInvokedBy === 'click') {

                    autoComplete.push(<AutoCompleteErrorMess
                        key={"No results returned for search '" + value + "'"}
                        name={"No results returned for search '" + value + "'"}
                    />);

                    this.setState({
                        autoComplete: autoComplete
                    });
                }
            }
        })
        .catch(err => {

            if (axios.isCancel(err)) {
                console.log('Request canceled', err);
            } else {

                console.log('Request failed', err);

                if (callInvokedBy === 'enter' || callInvokedBy === 'click') {

                    autoComplete.push(<AutoCompleteErrorMess
                        key={"No results returned for search '" + value +"'"}
                        name={"No results returned for search '" + value +"'"}
                    />);

                    this.setState({
                        autoComplete: autoComplete
                    });
                }
                
            }

        });
    }

    enterPressed = (e) => {

        if (e.key === 'Enter') {

            abort = true;
            this.searchClick(this.state.input, 'enter');
        }
    }

    orderResults = (results) => {

        let ordered = [];
        let notInCountry = [];
        let prevAddress = ""

        results.forEach((a) => {

            // remove duplicates
            if (a.address.freeformAddress !== prevAddress) {

                if (a.address.countryCode === this.state.location) {

                    ordered.push(a);

                } else {

                    notInCountry.push(a);
                }

                prevAddress = a.address.freeformAddress;
            }
        });

        return ordered.concat(notInCountry);

    }

    menuButtonClick = () => {
        this.props.menuButtonClicked(this.state.input);
        this.setState({ autoComplete: null });
    }

    render() {

        let searchAddressText = this.props.fullSearch ? 'Search Full Address' : 'Search Address';

        return (
            <React.Fragment>
                <header className='EztHeader'>
                <div className='EztHeaderDiv'>
                    <MenuButton MenuButtonClicked = {this.menuButtonClick}/>
                        <input className='InputBox'
                            type="text"
                            placeholder={searchAddressText}
                            onChange={this.onInput}
                            onKeyDown={this.enterPressed}
                            value={this.state.input}
                            onFocus={this.getLocation}

                        />
                        <button id='InputButton' type="submit" onClick={() => this.searchClick(this.state.input)} >
                        <img
                            className='searchImg'
                            src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxMDAwcHgiIGhlaWdodD0iMTAyN3B4IiB2ZXJzaW9uPSIxLjEiIHN0eWxlPSJzaGFwZS1yZW5kZXJpbmc6Z2VvbWV0cmljUHJlY2lzaW9uOyB0ZXh0LXJlbmRlcmluZzpnZW9tZXRyaWNQcmVjaXNpb247IGltYWdlLXJlbmRlcmluZzpvcHRpbWl6ZVF1YWxpdHk7IGZpbGwtcnVsZTpldmVub2RkOyBjbGlwLXJ1bGU6ZXZlbm9kZCIgdmlld0JveD0iMCAwIDY1MCA2NjkiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZGVmcz48c3R5bGUgdHlwZT0idGV4dC9jc3MiPjwhW0NEQVRBWy5maWwwIHtmaWxsOiM2NjY2NjY7ZmlsbC1ydWxlOm5vbnplcm99XV0+PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyX3gwMDIwXzEiPjxtZXRhZGF0YSBpZD0iQ29yZWxDb3JwSURfMENvcmVsLUxheWVyIi8+PHBhdGggY2xhc3M9ImZpbDAiIGQ9Ik0zNDAgMzQ1bDAgMCAxIC0xYzEyLC0xMiAyMiwtMjYgMjksLTQyIDYsLTE1IDEwLC0zMiAxMCwtNTAgMCwtMzYgLTE0LC02OCAtMzgsLTkxIC0yMywtMjMgLTU2LC0zOCAtOTEsLTM4IC0zNiwwIC02OCwxNCAtOTEsMzggLTIzLDIzIC0zOCw1NiAtMzgsOTEgMCwzNiAxNCw2OCAzOCw5MSAyMywyMyA1NiwzOCA5MSwzOCAxNywwIDMzLC0zIDQ4LC05IDE1LC02IDI5LC0xNSA0MSwtMjZ6bTEyNCA0MWwxNzAgMTc5YzIzLDI0IDIyLDYzIC0yLDg3IC0yNCwyMyAtNjMsMjIgLTg3LC0ybC0xNzAgLTE3OWMtMTAsNSAtMjAsMTAgLTMwLDE1IC0yOSwxMiAtNjEsMTggLTkzLDE4IC02OSwwIC0xMzIsLTI4IC0xNzgsLTc0IC00NiwtNDYgLTc0LC0xMDggLTc0LC0xNzggMCwtNjkgMjgsLTEzMiA3NCwtMTc4IDQ2LC00NiAxMDgsLTc0IDE3OCwtNzQgNjksMCAxMzIsMjggMTc4LDc0IDQ2LDQ2IDc0LDEwOCA3NCwxNzggMCwzNSAtNyw2OCAtMjAsOTggLTUsMTMgLTEyLDI1IC0xOSwzN3oiLz48L2c+PC9zdmc+'
                            alt ="Search Button"
                        />
                    </button>
                    <div className='EztHeaderLogo'>
                        <img className= 'EztLogo' src={EztImage} alt="EztLogo" />
                    </div>
                </div>
                </header>
                <ul className='AutoComplete'>
                    {this.state.autoComplete}
                </ul>
             </React.Fragment>

        );
    }
}

export default Toolbar;