export function atlasEventHandlerMove(e, pointPopup) {

    let offSet;

    // set the off set of popup
    if (pointPopup.autoAnchor === 'bottom' || typeof pointPopup.autoAnchor === 'undefined' || pointPopup.autoAnchor === 'bottom-right' || pointPopup.autoAnchor === 'bottom-left') {
        offSet = [0, -16];

    } else if (pointPopup.autoAnchor === 'right') {
        offSet = [-14, 0];

    } else if (pointPopup.autoAnchor === 'left') {
        offSet = [14, 0];

    } else if (pointPopup.autoAnchor === 'top' || pointPopup.autoAnchor === 'top-right' || pointPopup.autoAnchor === 'top-left') {
        offSet = [0, 16];
    }

    var popupOptions = pointPopup.getOptions();

    if (popupOptions.pixelOffset !== offSet) {

        popupOptions.pixelOffset = offSet;

        pointPopup.setOptions(popupOptions);

    }
}