import { showPopupForPoint} from './showPopupForPoint';

export function atlasClickEventHandlerClick(e, datasource, popup, copyDataToClipboard) {

    let point = e;

    if (point.shapes[0] && 'data' in point.shapes[0]) {

        showPopupForPoint(point.shapes[0], e.map, datasource, false, popup, copyDataToClipboard);
    }
    
}

