import React, { Component } from 'react';
import './autoCompleteErrorMess.css';
import { extend } from 'jquery';

class autoCompleteErrorMess extends Component {

    render() {
        return (
            <li className='autoCompleteErrorMessLi'
                id={this.props.name}
            >

                {this.props.name}

            </li>
        );
    }
}

export default autoCompleteErrorMess;