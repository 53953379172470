/*
    * Function: isc.rim.sphericalMercator.projectFromLatLon(lonLat)
    *
    * Static function.
    * Project a lon/lat point to a Spherical Mercator point
    *
    * Parameters:
    * lonLat - isc.rim.point where the x-coord is the longitude and the y-coord is the latitude
    *
    * Returns:
    * isc.rim.point
    */
export function projectFromLatLon (lonLat) {
    if (lonLat) {

        var _radius = 6378136.98;
 
        var _circumferenceHalf = _radius * Math.PI;  

        // break lonLat apart
        var lat = lonLat.getY();
        var lon = lonLat.getX();

        var xx;
        var yy;

        if (lat > 86.0) {
            yy = _circumferenceHalf;
        }
        else if (lat < -86.0) {
            yy = -_circumferenceHalf;
        }
        else {
            var latRad = (lat / 180.0) * Math.PI;
            var arc = Math.log(Math.tan(latRad) + (1.0 / Math.cos(latRad)));
            yy = (arc / Math.PI) * _circumferenceHalf;
        }

        xx = (lon / 180.0) * _circumferenceHalf;
        return (xx, yy);
    }
    return null;
};



/*
* Function: isc.rim.sphericalMercator.deprojectToLatLon(mp)
*
* Static function.
* De-project a Spherical Mercator point to a lat-lon
*
* Parameters:
* mp - isc.rim.point in Spherical Mercator
*
* Returns:
* isc.rim.point where the x-coord is the longitude and the y-coord is the latitude
*/
export function deprojectToLatLon (x, y) {
  
        var _radius = 6378136.98;
       
        var _circumferenceHalf = _radius * Math.PI;

        var a = Math.exp((y * 2) / _radius);
        var yy = ((Math.asin((a - 1) / (a + 1))) / Math.PI) * 180.0;
        var xx = (x / _circumferenceHalf) * 180.0;
        return { lon: xx, lat: yy };
  
};
