import * as atlas from 'azure-maps-control';
import * as mapsJs from '../mapsJs/mapsJs';
import * as spatial from 'azure-maps-spatial-io';

export function eztPointsToFeatureArr(eztPoints) {

    var points = eztPoints;

    var pointsJson = JSON.parse(points.json);

    var featureArray = [];

    // loop through each point in the custom points layer
    for (let i = 0; i < pointsJson.ShapeWkt.length; i++) {

        let latLon = pointsJson.ShapeWkt[i].replace("POINT(", "").replace(")", "").split(" ");

        // convert spherical mercator to lat and lon
        let geom = mapsJs.deprojectToLatLon(latLon[0], latLon[1]);
        let point = new atlas.data.Point(new atlas.data.Position(geom['lon'], geom['lat']));

        let pointFields = {};
        let pointNameId = "";

        // loop through the field metadata from the project and extract columns flagged with 'name' and 'callout'
        for (let j = 0; j < pointsJson.Fields.length; j++) {

            // grab the column with the name flag at set that as the nameId on the azure maps feature point
            if (pointsJson.Fields[j] === points.primaryNameCol.id) {
                pointNameId = pointsJson.Values[i][j];
            }

            // grab all the 'callout columns' and add to the azure maps feature point properties
            points.calloutColumns.forEach(x => {

                if (pointsJson.Fields[j] === x.id) {

                    pointFields[x.label] = pointsJson.Values[i][j];
                }

            });

        }

        let feature = new atlas.data.Feature(point, { nameId: pointNameId, callOutProperties: pointFields });

        featureArray.push(feature);

    }

    return featureArray;

}

export function eztMarkupToFeatureArr(markupJson) {

    var polyFeatureArr = [];

    // loop through each markup from the project
    for (let z = 0; z < markupJson.length; z++) {

        var sphericalMercWkt = markupJson[z].wkt;
        var name = markupJson[z].name;
        var group = markupJson[z].group
        var color = markupJson[z].color;
        var opacity = markupJson[z].opacity;

        var regEx = /[-]{0,1}[\d]*[.]{0,1}[\d]+\s[-]{0,1}[\d]*[.]{0,1}[\d]*/g;

        // using regular expression to find all coordinates and convert spherical mercator to lat and lon
        var latLonWkt = sphericalMercWkt.replace(regEx, function (x) {
            var latLon = x.split(" ");
            // lon = latLon[0]; lat = latLon[1];
            var coors = mapsJs.deprojectToLatLon(latLon[0], latLon[1]);
            return coors['lon'] + " " + coors['lat'];
        });

        // read the wkt string to the map control 
        let geometry = spatial.io.ogc.WKT.read(latLonWkt);

        let bbox = atlas.data.BoundingBox.fromData(geometry[0]);

        var feature = new atlas.data.Feature(geometry[0], { name: name, group: group, fillColor: color, fillOpacity: 0, eztMakupOpacity: opacity, bbox: bbox}, null);

        polyFeatureArr.push(feature);

    }

    return polyFeatureArr;
}