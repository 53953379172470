
export function atlasEventHandlerClose(ev, shapes, polygons, map, fromSideBar) {

    ev.target.close();

    if (fromSideBar && map.markers.getMarkers().length) {

        map.markers.clear();
    }

    polygons.forEach(x => {   

        for (let i = 0; i < shapes.length; i++) {

        let type = shapes[i].getType();

            if ((type === "Polygon" || type === "MultiPolygon") && shapes[i].getProperties().name === x.getProperties().name && shapes[i].fillOpacity !== 0) {

                shapes[i].addProperty('fillOpacity', 0);

            }
        } 

    });  

}

export function atlasEventHandlerClosePopup(ev, map, datasource, fromSideBar) {

    let shapes = datasource.getShapes();

    /*
    if (fromSideBar) {
        if (map.markers.getMarkers().length) {

            map.markers.clear();
        }
    }
    */

    for (var i = 0; i < shapes.length; i++) {

        let shape = shapes[i];
        let type = shape.getType();

        if ((type === "Polygon" || type === "MultiPolygon") && shape.getProperties().fillOpacity !== 0) {

            shape.addProperty('fillOpacity', 0);
        }

    }

}