import React, { Component } from 'react';
import './MapConsole.css';
import MapConsoleMessage from './MapConsoleMessage';

class MapConsole extends Component {

    render() {
        return (
            <div className='MapConsole' >

                <MapConsoleMessage mess={this.props.errorMessage} key={ this.props.errorMessage}/>
                {/*<p>{this.props.errorMessage}</p> */}
                  
            </div>
        );
    }
}

export default MapConsole;