import React, { Component } from 'react';
import './Version.css';
class Version extends Component {

    render() {

        return (
            <div className="eztLocatorVersion">
                <span>
                    EasyTerritory Locator v{this.props.number}
                </span>
            </div>
        );
    }
}

export default Version;