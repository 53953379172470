import React, { Component } from 'react';
import { Route } from 'react-router';
import { MapContainer } from './components/MapContainer';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

    render() {

        return (
            
            <Route exact path = '/:projectId?' component = { MapContainer } />
                
        );
    }
}
