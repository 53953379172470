import * as atlas from 'azure-maps-control';
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { pointInBoundingBox } from "./../Utils/PointInBoundingBox"; 

export function searchShowPolygon(shapes, state, lat, lon) {
    // filter out any shape that isn't a Polygon or MultiPolygon
    var polygons = shapes.filter(x => {
        return x.data.geometry && (x.data.geometry.type === "Polygon" || x.data.geometry.type === "MultiPolygon");
    });

    //var polygonSelected = '';
    var polygonSelectedArr = [];
    var polygonFound = false;

    polygons.forEach(x => {

        // loop through each polygon to see when the lat and lon falls in
        let terrCheck = booleanPointInPolygon([lon, lat], x.data);

        let props = x.getProperties();

        if (terrCheck) {

            // if point found in polygon, then show polygon
            x.addProperty('fillOpacity', props.eztMakupOpacity);

            polygonSelectedArr.push(x);

            polygonFound = true;

        } else {

            // if point is not found in polygon, then hide polygon
            if (props.fillOpacity !== 0) {
                x.addProperty('fillOpacity', 0);
            }
        }
    });

    if (polygonSelectedArr.length > 0) {

        state.map.setCamera({ bounds: atlas.data.BoundingBox.fromData(polygonSelectedArr), type: 'fly' });
    }

    if (!polygonFound) {

        // if no polygon is found from the lat and lon values than the points lands outside a polygon.

        var center = [parseFloat(lon), parseFloat(lat)];

        // center the map at that searched location
        state.map.setCamera({ center: center, type: 'fly' });

    }

    return polygonSelectedArr;
}

export function searchForPolygon(shapes, lat, lon) {

    let polygonSelectedArr = [];
    let polygonFound = false;

    for (var i = 0; i < shapes.length; i++) {

        const shape = shapes[i];

        if (shape.data.geometry.type === "Polygon" || shape.data.geometry.type === "MultiPolygon") {

            // loop through each polygon to see when the lat and lon falls in

            //do bounding box check first
            if (pointInBoundingBox(lon, lat, shape.getProperties().bbox)) {

                let terrCheck = booleanPointInPolygon([lon, lat], shape.data);

                if (terrCheck) {

                    // if point falls in territory add it to array
                    polygonSelectedArr.push(shape);

                }
            }
        }
    }

    return polygonSelectedArr;
}

export function showPolygons(highlightPolygons, shapes) {

    for (var i = 0; i < shapes.length; i++) {

        let shape = shapes[i];

        if (shape.data.geometry.type === "Polygon" || shape.data.geometry.type === "MultiPolygon") {

            let shapeProps = shape.getProperties();

            for (var j = 0; j < highlightPolygons.length; j++) {

                let highlightPolygon = highlightPolygons[j];
                let polygonProps = highlightPolygon.getProperties();

                if (shapeProps.name === polygonProps.name) {

                    if (shapeProps.fillOpacity !== shapeProps.eztMakupOpacity) {

                        shape.addProperty('fillOpacity', shapeProps.eztMakupOpacity);
                    }
                } else {

                    // if point is not found in polygon, then hide polygon
                    if (shapeProps.fillOpacity !== 0) {

                        shape.addProperty('fillOpacity', 0);
                    }

                }

            }
        }
    }

}