import * as atlas from 'azure-maps-control';
import { eztObject2Table } from '../Utils/BuildHtml';
import { atlasEventHandlerClose } from './atlasEventHandlerClose';
import { atlasEventHandlerMove } from './atlasEventHandlerMove';

export function showPopupForPoint(point, map, datasource, fromSideBar, popup, copyDataToClipboard) {

    const type = point.getType();

    if (type === "Point") {

        if (!fromSideBar && map.markers.getMarkers().length) {
            map.markers.clear();
        }

        map.setCamera({ center: point.getCoordinates(), type: 'fly' });

        let offSet;

        // set the position of popup
        if (popup.autoAnchor === 'bottom' || typeof popup.autoAnchor === 'undefined' || popup.autoAnchor === 'bottom-right' || popup.autoAnchor === 'bottom-left') {
            offSet = [0, -16];

        } else if (popup.autoAnchor === 'right') {
            offSet = [-14, 0];

        } else if (popup.autoAnchor === 'left') {
            offSet = [14, 0];

        } else if (popup.autoAnchor === 'top' || popup.autoAnchor === 'top-right' || popup.autoAnchor === 'top-left') {
            offSet = [0, 16];

        }

        const props = point.getProperties();

        const content = eztObject2Table(props, copyDataToClipboard);

        popup.setOptions({
            content: content,
            position: point.getCoordinates(),
            draggable: false,
            pixelOffset: offSet,
            fillColor: 'rgba(255, 255, 255, .94)',
            closeButton: true,

        });

        if (!popup.isOpen()) {

            popup.open(map);
        }

        var shapes = datasource.getShapes();

        var polygonSelectedArr = [];
        // loop through shapes in datasource and increase opacity for the markup 
        // that has a 1 to 1 relationship with the clicked point
        for (var i = 0; i < shapes.length; i++) {

            let type = shapes[i].getType();

            if (type === 'Polygon' || type === 'MultiPolygon') {

                let markup = shapes[i].getProperties();

                // if the point name is included in markup name
                if (markup.name.includes(props.nameId)) {

                    shapes[i].addProperty('fillOpacity', markup.eztMakupOpacity);

                    polygonSelectedArr.push(shapes[i]);

                    //e.map.setCamera({ bounds: shapes[i].getBounds(), type: 'fly' });

                } else {

                    if (markup.fillOpacity !== 0) {
                        shapes[i].addProperty('fillOpacity', 0);
                    }
                }
            }

        }

        // close event for popup either a popup clicked or popup from geocoded search
        //map.events.add('close', pointPopup, function (ev) { atlasEventHandlerClose(ev, shapes, polygonSelectedArr, map, fromSideBar) });

        //map.events.add('move', function (e) { atlasEventHandlerMove(e, pointPopup) });

        if (polygonSelectedArr.length) {
            map.setCamera({ bounds: atlas.data.BoundingBox.fromData(polygonSelectedArr), type: 'fly' });
        }
    }
    
}