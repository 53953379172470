import React, { Component } from 'react';
import './EztSplash.css';
import EztSplashImg from './images/EztSplashImg.svg'

class EztSplash extends Component {

    
    render() {
        return (
            <div className = 'SplashContainer' >
                <div className = 'Splash' >
                    {this.props.splash ? <img className='SplashImg' src={EztSplashImg} alt = "EasyTerritory"/>
                        : <p>Loading...</p>
                    }

                </div>
            </div>
        );
    }
}

export default EztSplash;