import './BuildHtml.css';

export function eztObject2Table(shapeProps, copyDataToClipboard) {

    let tableDiv = document.createElement('div');
    tableDiv.setAttribute('class', 'calloutTableDiv');

    let html = document.createElement("table");
    tableDiv.appendChild(html);
    html.setAttribute('class', 'calloutTable');

    Object.keys(shapeProps.callOutProperties).forEach(function (key, index) {

        if (shapeProps.callOutProperties[key]) {

            let htmlTr2 = document.createElement("tr");
            let htmlTr2dr = document.createElement("td");
            htmlTr2dr.setAttribute('class', 'tableDataKey');
            htmlTr2dr.textContent = key;
            html.appendChild(htmlTr2);
            htmlTr2.appendChild(htmlTr2dr);

            let value = shapeProps.callOutProperties[key].toString().toLowerCase();
            
            if (value.includes('https://') || value.includes('www') ||
                value.includes('http://') || key.toLowerCase().includes('email') || key.toLowerCase().includes('e-mail')
                || key.toLowerCase().includes('phone')
            ) {

                let htmlTr2dr2 = document.createElement("td");
                htmlTr2dr2.setAttribute('class', 'tableDataValue');
                var link = document.createElement('a');
                let p = document.createElement("p");
                p.setAttribute("class", "pLink");
                p.textContent = shapeProps.callOutProperties[key];

                link.href = shapeProps.callOutProperties[key];

                if (value.includes('www')) {
                 
                    link.href = "//" + shapeProps.callOutProperties[key];
                }

                if (key.toLowerCase().includes('email') || key.toLowerCase().includes('e-mail')) {
                    link.href = 'mailto:' + shapeProps.callOutProperties[key];
                } 

                if (key.toLowerCase().includes('phone')) {

                    link.href = 'tel:' + shapeProps.callOutProperties[key];
                }
                
                link.target = '_blank';
                link.appendChild(p);
                htmlTr2dr2.appendChild(link);
                htmlTr2.appendChild(htmlTr2dr2);

            } else {
                let htmlTr2dr2 = document.createElement("td");
                htmlTr2dr2.setAttribute('class', 'tableDataValue');

                let p = document.createElement("p");
                p.style.font = "12px/20px 'Helvetica Neue',Arial,Helvetica,sans-serif";
                p.style.padding = '0';
                p.style.margin = '0';
                p.style.color = 'black';
                p.textContent = shapeProps.callOutProperties[key];
                htmlTr2dr2.appendChild(p);
                htmlTr2.appendChild(htmlTr2dr2);

                if (shapeProps.callOutProperties[key].toString() === shapeProps.nameId) {
                    p.style.font = "14px/20px 'Helvetica Neue',Arial,Helvetica,sans-serif";
                    p.style.fontWeight = 'bold';
                }

            }
        }

    });

    if (copyDataToClipboard) {

        let copyToClipboard = document.createElement('button');
        copyToClipboard.setAttribute('class', 'copyToClipboard');
        copyToClipboard.title = "Copy contact info to clipboard";

        let copyToClipboardImg = document.createElement('img');
        copyToClipboardImg.setAttribute('class', 'copyToClipboardImg');
        copyToClipboardImg.src = "data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJjb3B5IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY29weSBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQzMy45NDEgNjUuOTQxbC01MS44ODItNTEuODgyQTQ4IDQ4IDAgMCAwIDM0OC4xMTggMEgxNzZjLTI2LjUxIDAtNDggMjEuNDktNDggNDh2NDhINDhjLTI2LjUxIDAtNDggMjEuNDktNDggNDh2MzIwYzAgMjYuNTEgMjEuNDkgNDggNDggNDhoMjI0YzI2LjUxIDAgNDgtMjEuNDkgNDgtNDh2LTQ4aDgwYzI2LjUxIDAgNDgtMjEuNDkgNDgtNDhWOTkuODgyYTQ4IDQ4IDAgMCAwLTE0LjA1OS0zMy45NDF6TTI2NiA0NjRINTRhNiA2IDAgMCAxLTYtNlYxNTBhNiA2IDAgMCAxIDYtNmg3NHYyMjRjMCAyNi41MSAyMS40OSA0OCA0OCA0OGg5NnY0MmE2IDYgMCAwIDEtNiA2em0xMjgtOTZIMTgyYTYgNiAwIDAgMS02LTZWNTRhNiA2IDAgMCAxIDYtNmgxMDZ2ODhjMCAxMy4yNTUgMTAuNzQ1IDI0IDI0IDI0aDg4djIwMmE2IDYgMCAwIDEtNiA2em02LTI1NmgtNjRWNDhoOS42MzJjMS41OTEgMCAzLjExNy42MzIgNC4yNDMgMS43NTdsNDguMzY4IDQ4LjM2OGE2IDYgMCAwIDEgMS43NTcgNC4yNDNWMTEyeiI+PC9wYXRoPjwvc3ZnPg=="

        copyToClipboard.appendChild(copyToClipboardImg)
        copyToClipboard.addEventListener('click', () => {

            let copied = "";
            Object.keys(shapeProps.callOutProperties).forEach(function (key, index) {
                copied += key + " : " + shapeProps.callOutProperties[key].toString() + "\n";

            });

            // for chrome
            navigator.permissions.query({ name: "clipboard-write" }).then(result => {
                if (result.state == "granted" || result.state == "prompt") {

                    navigator.clipboard.writeText(copied).then(function () {

                    }, function () {
                        console.log('EZT Locator APP - failed to copy to clipboard');
                    });

                }
            }, function () {
                // for firefox
                navigator.clipboard.writeText(copied).then(function () {

                }, function () {
                    console.log('EZT Locator APP - failed to copy to clipboard');
                });
            });
            
        });
       
        tableDiv.appendChild(copyToClipboard);
    }

    return tableDiv;
}