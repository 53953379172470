import React, { Component } from 'react';
import './Utils/BuildHtml.css';
import { showPopupForPoint } from './Atlas/showPopupForPoint';

class SideMenuPoints extends Component {

    tableClick = (e) => {

        this.props.datasource.getShapes().forEach(x => {

            if (x.getType() === "Point" && x.getProperties().nameId === e.nameId) {

                showPopupForPoint(x, this.props.map, this.props.datasource, true, this.props.popup, this.props.copyCalloutToClipboard);
            }   
        });
        
    };

    render() {

        let trData = { nameKey: '', nameValue: '' };

        if (this.props.points) {

            Object.keys(this.props.points.callOutProperties).forEach((key, index) => {
                
                if (this.props.points.callOutProperties[key] === this.props.points.nameId) {
                    trData.nameKey = key;
                    trData.nameValue = this.props.points.callOutProperties[key];
                }

            });

        }

        return (
            <table className="calloutTableSideMenu" key={this.props.points.nameId} onClick={() => this.tableClick(this.props.points)}>
                <tbody >
                    <tr>
                        <td className="tableDataKey">{trData.nameKey}</td>
                        <td className="tableDataKeyMenu"><strong>{trData.nameValue}</strong></td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default SideMenuPoints;