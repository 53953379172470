import React, { Component } from 'react';
import * as atlas from 'azure-maps-control';
import axios from 'axios';
import Map from './Map';
import EztSplash from './EztSplash';
import MapConsole from './MapConsole';
import { countryCodeList} from './countryCodes/countryCodes';

export class MapContainer extends Component {
 
    state = {
        eztProjectJson: null,
        azureKey: '',
        atlasSupported: true,
        showMenu: false,
        errorMessage: '',
        searchCountryCode: '',
        copyCalloutToClipboard: false
    }

    componentDidMount() {

        let projectId = this.props.match.params.projectId;

        let search = this.props.location.search;

        let searchCountryCode = '';

        let paramsInUrl = false;

        let countryCodeValid = false;

        if (search && search.toLowerCase().startsWith('?searchcountrycode=')) {

            paramsInUrl = true;

            let countryCode = search.split('=');

            if (countryCode[1]) {

                for (var i = 0; i < countryCodeList.length; i++) {

                    if (countryCode[1].toUpperCase() === countryCodeList[i]) {

                        countryCodeValid = true;
                    }
                }

                if (countryCodeValid) {

                    searchCountryCode = countryCode[1];
                }

            }

        }

        let searchCountryCodeValue = null;
        let searchCountryCodeError = false;

        if (paramsInUrl && countryCodeValid) {

            searchCountryCodeValue = searchCountryCode
        } else if (paramsInUrl && !countryCodeValid) {
            searchCountryCodeError = true;
        }

        if (projectId && !searchCountryCodeError) {

            var azureMapsSupport = true;

            if (!atlas.isSupported()) {

                console.log('EZT Locator App- Your browser is not supported by Azure Maps');
                azureMapsSupport = false;

            } else if (!atlas.isSupported(true)) {

                console.log('EZT Locator App- Your browser is supported by Azure Maps, but may have major performance caveats.');
            }

            axios.get('api/Project?id=' + projectId).then(x => {

                this.setState({ eztProjectJson: x.data, azureKey: x.data.azureKey, atlasSupported: azureMapsSupport, searchCountryCode: searchCountryCode, copyCalloutToClipboard: x.data.copyCalloutToClipboard });

            }).catch(err => {

                this.setState({ errorMessage: 'Call to get EZT project failed: ' + err.message });

            });

        } else if (projectId && searchCountryCodeError){

            this.setState({ errorMessage: "Country Code not found. Please enter valid 'searchCountryCode' value"});
        }

    }
 
    renderCheck = () => {

        if (this.state.eztProjectJson && this.state.atlasSupported && !this.state.errorMessage) {

            return <Map project={this.state.eztProjectJson}
                azureKey={this.state.azureKey}
                searchCountryCode={this.state.searchCountryCode}
                key="eztMap"
                copyCalloutToClipboard={this.state.copyCalloutToClipboard}
                    />
        } else {

            var eztSplash = [];
            eztSplash.push(<EztSplash key="eztSplash" splash={false} />);

            if (!this.state.atlasSupported) {

                this.setState({ errorMessage: "Your browser is not supported. Please use a modern browser" });

            } else if (this.state.errorMessage) {

                eztSplash.push(<MapConsole errorMessage={this.state.errorMessage} key="eztMapConsole" />);
            }

            return eztSplash;
          
        }

    }

    render() {

        let map = this.renderCheck();
        
        return (
           <React.Fragment> 
                {map}
            </React.Fragment>
      );
            
  }
}
