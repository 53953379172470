import React, { Component } from 'react';
import './MenuButton.css';
class MenuButton extends Component {

    render() {

        return (
            <button id="menuButton" onClick={this.props.MenuButtonClicked }>
                <img id="menuButtonIcon"
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjRwdCIgaGVpZ2h0PSIyNHB0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSI+PGcgaWQ9InN1cmZhY2U3Njc1MzA0Ij48cGF0aCBzdHlsZT0iIHN0cm9rZTpub25lO2ZpbGwtcnVsZTpub256ZXJvO2ZpbGw6cmdiKDQwJSw0MCUsNDAlKTtmaWxsLW9wYWNpdHk6MTsiIGQ9Ik0gMiAxMSBMIDIyIDExIEwgMjIgMTMgTCAyIDEzIFogTSAyIDUgTCAyMiA1IEwgMjIgNyBMIDIgNyBaIE0gMiAxNyBMIDIyIDE3IEwgMjIgMTkgTCAyIDE5IFogTSAyIDE3ICIvPjwvZz48L3N2Zz4="
                    alt="Menu Button"
                />
                    
            </button> 
        );
    }
}

export default MenuButton;